<template>
  <div class="bedList fatherHeight contBox">
    <el-tabs v-model="tagName" class="patent-tabs" @tab-click="handleClick">
      <el-tab-pane label="透析机" name="dialysisMachine">
        <dialysisMachine ref="dialysisMachineRef"></dialysisMachine>
      </el-tab-pane>
      <!-- <el-tab-pane label="水处理机" name="dispose">
        <dialysis ref="dialysisRef"></dialysis>
      </el-tab-pane>
      <el-tab-pane label="其他" name="other">
        <dialysis ref="dialysisRef"></dialysis>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import dialysisMachine from './dialysisMachine/index.vue'

const tagName = ref('dialysisMachine')
const dialysisMachineRef = ref(null)
const dialysisRef = ref(null)
const handleClick = (tab) => {
  if (tab.props.name == 'dialysisMachine') {
    dialysisMachineRef.value.initData()
  } else {
    dialysisRef.value.initData()
  }
}
</script>

<style scoped lang="scss">
.bedList {
  &:deep(.el-tabs) {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 48px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
